window.validateEmail = (raw) => {
    var emails = raw.split(',');
    var valid = true;
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
        if (emails[i] === "" || !regex.test(emails[i].replace(/\s/g, ""))) {
            valid = false;
        }
    }
    return valid;
};
window.error = (e, custom_msg = null) => {
    e.siblings("span.error").show();
    if (!custom_msg) return e.siblings("span.error").html('Please fill out this field.');
    else return e.siblings("span.error").html(custom_msg);
}
window.cleanAll = () => {
    $("span.error").hide();
}
(function ($) {
    $('input, textarea').on('focus', function () {
        cleanAll();
    });
    $('input, textarea').on('keydown', function () {
        var keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode == 13) {
            window.dd = $(this);
            $(this).parents('form').find('.form-submit-button').trigger('click');
        }
    });
}(jQuery));
